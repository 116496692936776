import React from 'react';
import styled from 'styled-components';
import { Container } from '../misc';
import Tile from './tile';
import { graphql, useStaticQuery } from 'gatsby';

const Background = styled.div`
  margin-top: 40px;
  background: ${({ theme }) => theme.colors.green};
`;

const StyledContainer = styled(Container)`
  padding: 40px 20px;
  color: ${({ theme }) => theme.colors.white};

  h2 {
    font-size: 3rem;
    text-align: center;
  }
`;

const PartnersContainer = styled(Container)`
  padding-top: 40px;
`;

const Information = () => {
  const { sponsors } = useStaticQuery(graphql`
    {
      sponsors: allDatoCmsSponsor(sort: { fields: order, order: ASC }) {
        nodes {
          name
          fbLink
          description
          benefits
          logo {
            fixed(width: 240) {
              ...GatsbyDatoCmsFixed_tracedSVG
            }
          }
        }
      }
    }
  `);

  return (
    <>
      <Background>
        <StyledContainer>
          <h2>ZOBACZ PARTNERÓW AKCJI</h2>
        </StyledContainer>
      </Background>
      <PartnersContainer>
        {sponsors.nodes.map(({ name, description, fbLink, benefits, logo }) => (
          <Tile key={name} logo={logo} description={description} name={name} offer={benefits} fb={fbLink} />
        ))}
      </PartnersContainer>
    </>
  );
};

export default Information;
