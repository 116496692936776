import * as React from 'react';
import { Information, Partners } from '../components';
import { Layout } from '../components/misc';

const IndexPage = () => {
  return (
    <Layout>
      <main>
        <Information />
        <Partners />
      </main>
    </Layout>
  );
};

export default IndexPage;
