import React from 'react';
import styled from 'styled-components';
import media from '../../utils/media';
import FBLogo from 'assets/fb-logo.png';
// import Image from '../misc/image';
import Image from 'gatsby-image';

const TileContainer = styled.div`
  display: flex;
  border-radius: 10px;
  box-shadow: ${({ theme }) => theme.boxShadows.base};
  padding: 20px;
  margin-bottom: 40px;

  ${media.tablet} {
    flex-direction: column;
  }
`;

const LogoContainer = styled.div`
  min-width: 250px;
  max-width: 250px;
  border-right: 1px solid ${({ theme }) => theme.colors.green};
  padding-right: 20px;

  ${media.tablet} {
    border-right: none;
    padding-right: 0;
    padding-bottom: 20px;
    margin: 0 auto;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: auto;
  }
`;

const FBContainer = styled.div`
  width: 35px;

  img {
    object-fit: cover;
    width: 100%;
    height: auto;
  }
`;

const TileDescription = styled.div`
  padding-left: 20px;

  ${media.tablet} {
    padding-left: 0;
    margin-top: 0;
  }
`;

const Description = styled.p`
  font-style: italic;
`;

const Offer = styled.p`
  font-size: 0.875rem;
  font-weight: 700;
`;

const Tile = ({ name, logo, description, offer, fb }) => (
  <TileContainer>
    <LogoContainer>
      <Image fixed={logo.fixed} alt={name} />
    </LogoContainer>
    <TileDescription>
      <h3>{name}</h3>
      <Description>{description}</Description>
      <Offer>{offer}</Offer>
      <FBContainer>
        <a href={fb} target="_blank" rel="noreferrer">
          <img src={FBLogo} alt="strona-na-fb" />
        </a>
      </FBContainer>
    </TileDescription>
  </TileContainer>
);

export default Tile;
