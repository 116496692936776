import React from 'react';
import styled from 'styled-components';
import { Container } from '../misc';

const StyledContainer = styled(Container)`
  padding-top: 40px;
`;

const Span = styled.span`
  color: ${({ theme }) => theme.colors.green};
  font-weight: 600;
`;

const Information = () => (
  <StyledContainer>
    <h2>Propozycja od kibiców - dla kibiców!</h2>
    <p>
      <Span>#GramyRazem</Span> to coś na wzór karty lojalnościowej, gdzie hasło "KK Zastal" sprawi, że zakupy lub usługi
      u naszych partnerów będą tańsze i - mamy nadzieję - przyjemniejsze!
    </p>
    <p>
      Na hasło <Span>KK ZASTAL</Span> otrzymacie zniżki na usługi lub produkty od partnerów akcji, których listę
      znajdziecie poniżej.
    </p>
    <p>
      <b>Kibice płacą mniej!</b>
    </p>
  </StyledContainer>
);

export default Information;
